import React from 'react';
import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Pagination from '../components/Pagination';
import Button from '../components/Button';
import styled, { ThemeProvider } from 'styled-components';
import { cwTheme } from '../theme/theme.js';
import Img from 'gatsby-image';
import SignUpForm from '../components/SignUpForm';
import PreHeader from '../components/PreHeader';

const BlogHero = styled.section`
	padding-top: 1rem;
	text-align: center;
	background-color: ${cwTheme.white};
`;

const Container = styled.div`
	max-width: 75rem;
	margin: 0 auto;
	box-sizing: content-box;
	padding: 0 1rem;
`;

const BlogHeader = styled.h2`
	margin-top: 0;
	font-family: ${cwTheme.serif};
	margin-bottom: 0rem;
`;

const BlogMain = styled.section`
	// background-color: #e6e6e6;
	// padding-top: 1rem;
`;

const InnerWrapper = styled.div`
	display: flex;
	flex-direction: column-reverse;
	max-width: 75rem;
	margin: 0 auto;
	box-sizing: content-box;
	padding: 0 .5rem;

	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		flex-direction: column-reverse;
		padding: 0 1rem;
	}
`;
const IndexContainer = styled.div`
	flex: 4;
	margin-left: 0.5rem;
	margin-right: 0.5rem;

	display: grid;

	grid-template-columns: 1fr;
	grid-template-rows: auto;
	grid-gap: 3rem;

	@media (min-width: ${cwTheme.smallBreakPoint}) {
		grid-template-columns: 1fr 1fr;
	}

	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		margin-bottom: 0;
		grid-template-columns: 1fr 1fr 1fr;
	}
`;

const PostCard = styled.div`
	// box-shadow: ${cwTheme.boxShadow};
	// border: ${cwTheme.border};
	// border-radius: ${cwTheme.borderRadius};
	@media (min-width: ${cwTheme.smallBreakPoint}) {
		${(props) => (props.featured == 0 ? 'grid-column-start: 1; grid-column-end: 3;' : '')};
	}

	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		${(props) => (props.featured == 0 ? 'grid-column-start: 1; grid-column-end: 4;' : '')};
	}
`;

const BlogLinkWrapper = styled.div`
	min-height: 100%;
	background: rgba(255, 255, 255, 1);
	text-decoration: none;
	color: #444;
	display: flex;
	flex-direction: column;

	article {
		padding-bottom: .25rem;
		display: flex;
		flex-direction: column;
		flex: 1;
		padding: 0 10px 5px 10px;
		align-self: ${(props) => (props.featured == 0 ? 'center' : 'left')};
	}

	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		flex-direction: ${(props) => (props.featured == 0 ? 'row' : 'column')};
	}
`;

const PostTitle = styled(Link)`
	flex: ${(props) => (props.featured == 0 ? '' : '1')};
	padding-top: ${(props) => (props.featured == 0 ? '0' : '10px')};
	padding-bottom: 10px;
	line-height: 1.18421053em;
	display: block;
	font-weight: 600;
	color: inherit;
	text-decoration: none;
	display: block;
	font-size: ${(props) => (props.featured == 0 ? '1.563em' : '1.25em')};

	max-width: 100%;
	&:hover {
		color: ${cwTheme.blackHover}
	}

	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		max-width: 400px;
	}

	
`;

const ImageLink = styled(Link)`
flex-grow: ${(props) => (props.featured == 0 ? '1.5' : '')};
margin-right: 0;

@media (min-width: ${cwTheme.mediumBreakPoint}) {
	margin-right: ${(props) => (props.featured == 0 ? '2rem' : '0')};
}
`;

const PostExcerpt = styled(Link)`
	flex: 1;
	margin-top: 0;
	margin-bottom: 1em;
	display: block;
	color: ${cwTheme.black};
	text-decoration: none;
	&:hover {
		color: ${cwTheme.blackHover};
	}
`;

const TagContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	a {
		background-color: ${cwTheme.secondaryBlue};
		padding: 2px 8px;
		border-radius: 16px;
		text-decoration: none;
		color: white;
		font-size: 12px;
		margin-right: 5px;
		margin-bottom: 5px;
		transition: .3s;
	}

	a:hover {
		background-color: ${cwTheme.lightBlue};
	}
`;

const CategoriesContainer = styled.div`
	flex: 1;
	margin: 0 .5rem 1rem .5rem;
	display: flex;
	align-items: center;
	flex-direction: column;

	h6 {
		margin: 0;
	}

	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		margin-left: 2.5rem;
		margin-botton: 0;
		display: block;
	}
`;
const CategoriesList = styled.ul`
	padding-left: 5px;
	text-decoration: none;
	list-style: none;
	margin-top: 0;
	line-height: 2;

	a {
		text-decoration: none;
		color: ${cwTheme.blackHover};
		font-size: 1rem;
	}

	a:hover {
		color: rgba(61, 61, 61, .5);
	}

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	text-align: center;

	li {
		padding: .2rem .5rem;
	}


`;

const FeaturedHeader = styled.h6`
	display: ${(props) => (props.featured == 0 ? 'block' : 'none')};
	margin-top: 1rem;
	margin-bottom: 0;
	font-weight: 500;
	color: ${cwTheme.secondaryBlue};
	letter-spacing: 1.5px;
`;

const TimeToRead = styled.span`
	color: ${cwTheme.blackHover};
	font-size: .8em;
`;

const Meta = styled.div`
	display: flex;
	justify-content: space-between;
	padding-top: 10px;
`;

const CategoryHome = styled.li`
	padding: .2rem .5rem;
	a {
		font-weight: 700;
	}
`;

const FeaturedExcerptWapper = styled.div`
	max-width: 100%;
	@media (min-width: ${cwTheme.mediumBreakPoint}) {
	max-width: 400px;
	} 
`;

const BlogHome = ({ data, pageContext }) => {
	console.log(data);
	console.log(pageContext);

	const posts = data.allContentfulPost.edges;
	const { basePath } = pageContext;
	const allTags = data.allContentfulTag.edges;

	return (
		<ThemeProvider theme={{ mode: 'light' }}>
			<Layout>
				<SEO title="Blog" />

				<BlogHero>
					<Container>
						<BlogHeader>The Mindful Reader</BlogHeader>
					</Container>
				</BlogHero>

				<BlogMain>
					<InnerWrapper>
						<IndexContainer>
							{posts.map(({ node: post }, i) => (
								<PostCard key={post.id} featured={i}>
									<BlogLinkWrapper featured={i}>
										<ImageLink to={basePath + post.slug} featured={i}>
											<Img
												style={{ height: '100%' }}
												backgroundColor={cwTheme.lightBlue}
												sizes={post.mainImage.fluid}
											/>
										</ImageLink>

										<article>
											<FeaturedHeader featured={i}>FEATURED</FeaturedHeader>
											<PostTitle to={basePath + post.slug} featured={i}>
												{post.title}
											</PostTitle>
											{i == 0 ? (
												<FeaturedExcerptWapper>
													<PostExcerpt to={basePath + post.slug}>
														{post.body.childMarkdownRemark.excerpt}
													</PostExcerpt>
													<Button
														link={basePath + post.slug}
														hoverColor={cwTheme.primaryHover}
														color={cwTheme.primaryBlue}
													>
														Read More
													</Button>
												</FeaturedExcerptWapper>
											) : (
													''
												)}
											<TagContainer>
												{post.tags == null ? (
													''
												) : (
														post.tags.map((postTag) => (
															<Link
																key={'pill' + postTag.id}
																to={'/blog/topic/' + postTag.slug}
															>
																{postTag.title}
															</Link>
														))
													)}
											</TagContainer>
										</article>
									</BlogLinkWrapper>
								</PostCard>
							))}
						</IndexContainer>

						<CategoriesContainer>
							<CategoriesList>
								<CategoryHome>
									<Link activeStyle={{ color: cwTheme.black }} to={'/blog'}>
										All Topics
									</Link>
								</CategoryHome>
								{allTags.map(({ node: tag }) => (
									<li key={'topic' + tag.id}>
										<Link
											to={'/blog/topic/' + tag.slug}
											activeStyle={{ color: cwTheme.black }}
											partiallyActive={true}
										>
											{tag.title}
										</Link>
									</li>
								))}
							</CategoriesList>
						</CategoriesContainer>
					</InnerWrapper>
				</BlogMain>

				<Pagination context={pageContext} />

				<SignUpForm header={'Enjoyed these posts? Receive the next one in your inbox!'} />
			</Layout>
		</ThemeProvider>
	);
};

export const query = graphql`
	query($skip: Int!, $limit: Int!) {
		allContentfulPost(sort: { fields: [publishDate], order: DESC }, limit: $limit, skip: $skip) {
			totalCount
			edges {
				node {
					title
					id
					slug
					publishDate(formatString: "MMMM DD, YYYY")
					tags {
						title
						slug
						id
					}
					body {
						childMarkdownRemark {
							excerpt
							timeToRead
						}
					}
					mainImage {
						fluid(maxWidth: 800, maxHeight: 500, quality: 100) {
							src
							...GatsbyContentfulFluid_noBase64
						}
					}
				}
			}
		}
		allContentfulTag(sort: { fields: title, order: ASC }) {
			edges {
				node {
					slug
					title
					id
				}
			}
		}
	}
`;

export default BlogHome;
